import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'firstWord'
})
export class FirstWord implements PipeTransform {
  transform(value: string, args: any[]): string | boolean {
    if (!value) {
      return '';
    }
    return value.split('')[0];
  }
}
