import {NgModule} from '@angular/core';
import {FirstWord} from './firstword';

@NgModule({
  imports: [],
  declarations: [FirstWord],
  exports: [FirstWord]
})
export class CustomPipesModules {
}
